import { createRouter } from '@rehnport/rp-framework'

export const routes = [
  {
    path: '/ytlist',
    component: () => import(/* webpackChunkName: "ytlist" */ './views/Module'),
    children: [
      {
        path: 'home',
        name: 'ytlist/home',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/Home')
      },
      {
        path: 'sync',
        name: 'ytlist/sync',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/Sync/Index')
      },
      {
        path: 'search',
        name: 'ytlist/search',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/SearchChannel/Index')
      },
      {
        path: 'ytvideo/:id',
        name: 'ytlist/ytvideo',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/YtVideoDetails'),
        props: true
      },
      {
        path: 'channel',
        name: 'ytlist/channel-list',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/Channel/List')
      },
      {
        path: 'channel/:id/:page?',
        name: 'ytlist/channel-details',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/Channel/Details'),
        props: true
      },
      {
        path: 'playlist',
        name: 'ytlist/playlist-list',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/Playlist/List')
      },
      {
        path: 'playlist/create',
        name: 'ytlist/playlist-create',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/Playlist/Create'),
        props: true
      },
      {
        path: 'playlist/:id',
        name: 'ytlist/playlist-details',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/Playlist/Details'),
        props: true
      },
      {
        path: 'playlist/edit/:id',
        name: 'ytlist/playlist-edit',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/Playlist/Edit'),
        props: true
      },
      {
        path: 'playlist/item/:id',
        name: 'ytlist/playlist-item',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/Playlist/ItemDetails'),
        props: true
      },
      {
        path: 'notes',
        name: 'ytlist/notes-list',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/Notes/List')
      },
      {
        path: 'notes/create',
        name: 'ytlist/notes-create',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/Notes/Create')
      },
      {
        path: 'notes/:id',
        name: 'ytlist/notes-details',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/Notes/Details'),
        props: true
      },
      {
        path: 'notes/edit/:id',
        name: 'ytlist/notes-edit',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/Notes/Edit'),
        props: true
      },
      {
        path: 'event',
        name: 'ytlist/event-list',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/Event/List')
      },
      {
        path: 'event/create',
        name: 'ytlist/event-create',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/Event/Create')
      },
      {
        path: 'event/edit/:id',
        name: 'ytlist/event-edit',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/Event/Edit'),
        props: true
      },
      {
        path: 'event/:id',
        name: 'ytlist/event-details',
        component: () => import(/* webpackChunkName: "ytlist" */ './views/Event/Details'),
        props: true,
        redirect: to => {
          return { name: 'ytlist/event-details/round-summary', params: to.params }
        },
        children: [
          {
            path: '',
            name: 'ytlist/event-details/round-summary',
            component: () => import(/* webpackChunkName: "ytlist" */ './views/Event/partials/RoundSummary'),
            props: true
          },
          {
            path: 'round/:roundId',
            name: 'ytlist/event-details/round-details',
            component: () => import(/* webpackChunkName: "ytlist" */ './views/Event/partials/RoundDetails'),
            props: true
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  mode: 'history',
  base: process.env.BASE_URL
})

export default router
