import { routes } from './router'
import { modules as storeModules } from './store'

export const plugin = {
  menuItems: [
    { name: 'ytlist/home', label: 'YTList' },
    { name: 'ytlist/sync', label: 'Sync' },
    { name: 'ytlist/playlist-list', label: 'Playlists' },
    { name: 'ytlist/event-list', label: 'Events' }
  ],
  homeItems: [
    { route: 'ytlist/home', label: 'Youtube List', icon: ['fab', 'youtube'] }
  ],
  routes,
  storeModules
}
