import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { run } from '@rehnport/rp-framework'
import './style.scss'
import { plugin } from './plugin'
import axios from './plugins/axios'
import youtubeIframe from './plugins/youtube-iframe'
import config from './config'

Vue.use(axios)
Vue.use(youtubeIframe)

run({
  rootComponent: App,
  rootComponentMount: '#app',
  router,
  store,
  plugins: [plugin],
  // auth: null
  auth: {
    authority: config.AUTHORITY,
    scopes: ['portal'],
    redirect: window.location.origin + '/auth/callback',
    silentRedirect: window.location.origin + '/auth/silent'
  }
})
