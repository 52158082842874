import axios from 'axios'
import config from '../config'
import store from '../store'

const api = axios.create({
  baseURL: config.API_URL
})

function install (vue) {
  vue.prototype.$api = api

  api.interceptors.request.use(cfg => {
    if (store.getters['global/isAuthenticated']) {
      cfg.headers.Authorization = 'Bearer ' + store.state.global.user.access_token
    }
    return cfg
  })
}

export { api as $api }

export default {
  install
}
