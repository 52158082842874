import orderBy from 'lodash.orderby'
import { $api } from '../plugins/axios'

const defaultState = () => ({
  info: null,
  eventData: null,
  roundsData: [],
  revealedEventRoundHoles: null,
  currentRoundOrdinal: 1
})

export default {
  namespaced: true,
  state () {
    return {
      ...defaultState()
    }
  },
  getters: {
    getEventInfo: (state) => {
      return state.info
    },
    getEventData: (state) => {
      return state.eventData
    },
    getRoundsData: (state) => {
      return state.roundsData
    },
    getRevealedEventRoundHoles: (state) => {
      return state.revealedEventRoundHoles[state.currentRoundOrdinal]
    },
    getRevealedEventRoundHoles2: (state) => {
      return state.revealedEventRoundHoles
    },
    getCurrentRoundOrdinal: (state) => {
      return state.currentRoundOrdinal
    }
  },
  mutations: {
    reset (state) {
      Object.assign(state, defaultState())
    },
    setEventInfo (state, data) {
      state.info = data
    },
    setEventData (state, data) {
      state.eventData = data
    },
    setRoundsData (state, data) {
      state.roundsData = data
    },
    setRevealedEventRoundHoles (state, data) {
      const model = {}
      for (let i = 1; i <= data.length; i++) {
        model[i] = data[i - 1]
      }
      state.revealedEventRoundHoles = model
    },
    increasedRevealedEventRoundHoles (state) {
      state.revealedEventRoundHoles[state.currentRoundOrdinal]++
    },
    decreasedRevealedEventRoundHoles (state) {
      state.revealedEventRoundHoles[state.currentRoundOrdinal]--
    },
    setCurrentRoundOrdinal (state, roundOrdinal) {
      state.currentRoundOrdinal = roundOrdinal
    }
  },
  actions: {
    loadEventData ({ commit }, id) {
      commit('reset')
      return $api.get('/api/ytlist/event/' + id)
        .then(response => {
          const info = {
            id: response.data.id,
            name: response.data.name,
            externalId: response.data.externalId,
            division: response.data.division,
            playlistId: response.data.playlistId
          }
          commit('setEventInfo', info)

          if (response.data.eventData != null) {
            const eventData = JSON.parse(response.data.eventData)
            commit('setEventData', eventData)
            const roundsData = []

            for (const roundJson of response.data.roundsData.roundsData) {
              const round = JSON.parse(roundJson)
              if (Array.isArray(round.data)) {
                const poolsSorted = orderBy(round.data, ['pool'], ['asc'])
                round.data = poolsSorted[0]
              }
              roundsData.push(round)
            }

            commit('setRoundsData', roundsData)
            commit('setRevealedEventRoundHoles', roundsData.map(_ => 0))
          }
        })
    }
  }
}
