import ytlist from './ytlist'
import { createStore } from '@rehnport/rp-framework'

export const modules = {
  ytlist
}

const store = createStore()

export default store
