import { $api } from '../plugins/axios'
import currentEvent from './currentEvent'

const KEYWORD_IDENTIFIER = Object.freeze({
  ROUND: 'round',
  EVENT: 'event',
  MPO: 'mpo',
  FPO: 'fpo'
})

export default {
  namespaced: true,
  modules: {
    currentEvent
  },
  state () {
    return {
      youtubeReady: false,
      round: [],
      event: [],
      mpo: [],
      fpo: []
    }
  },
  getters: {
    roundKeywords (state) {
      return state[KEYWORD_IDENTIFIER.ROUND].map(x => x.value)
    },
    eventKeywords (state) {
      return state[KEYWORD_IDENTIFIER.EVENT].map(x => x.value)
    },
    mpoKeywords (state) {
      return state[KEYWORD_IDENTIFIER.MPO].map(x => x.value)
    },
    fpoKeywords (state) {
      return state[KEYWORD_IDENTIFIER.FPO].map(x => x.value)
    }
  },
  mutations: {
    setAllKeywords (state, { identifier, keywords }) {
      state[identifier] = keywords
    },
    youtubeReady (state) {
      state.youtubeReady = true
    }
  },
  actions: {
    loadAll ({ commit }) {
      Object.values(KEYWORD_IDENTIFIER)
        .forEach(identifier => {
          $api.get('/api/ytlist/keywords/' + identifier)
            .then(response => {
              commit('setAllKeywords', { identifier, keywords: response.data.keywords })
            })
        })
    },
    storeAll ({ dispatch, getters }) {
      // dispatch('storeBatch', { identifier: KEYWORD_IDENTIFIER.ROUND, values: getters.roundKeywords })
      // dispatch('storeBatch', { identifier: KEYWORD_IDENTIFIER.EVENT, values: getters.eventKeywords })
      // dispatch('storeBatch', { identifier: KEYWORD_IDENTIFIER.MPO, values: getters.mpoKeywords })
      // dispatch('storeBatch', { identifier: KEYWORD_IDENTIFIER.FPO, values: getters.fpoKeywords })
    },
    storeBatch (_, { identifier, values }) {
      let sortOrder = 0
      $api.post(
        '/api/ytlist/keywords-batch/' + identifier,
        {
          listIdentifier: identifier,
          values: values.map(value => ({
            value,
            sortOrder: (sortOrder += 100)
          }))
        }
      )
    }
  }
}
