import store from '../store'

const service = {
  YT: null
}

function install (vue) {
  vue.prototype.$youtube = service

  window.onYouTubeIframeAPIReady = function () {
    service.YT = window.YT
    store.commit('ytlist/youtubeReady')
  }
}

export default {
  install
}
